<template>
	<div>
		<div class="df jcsb aic" v-if="isEdit">
			<div class="df aic" style="height: 78px;">
				<el-upload class="avatar-uploader" :headers="{token}"
					action="http://chaofan.fqcp009.top/api/v1/apiUpload" :show-file-list="false"
					:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
					<img v-if="params.avatar" :src="params.avatar" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div class="df fdc jcsb" style="margin-left: 20px;height: 100%;">
					<a-input allowClear placeholder="请输入用户名" v-model="params.username">
						<a-icon slot="prefix" type="user" />
					</a-input>
					<a-input allowClear placeholder="请输入手机号" v-model="params.phone">
						<a-icon slot="prefix" type="phone" />
					</a-input>
				</div>
			</div>
			<a-button type="primary" style="margin-right: 20px;" @click="changeUserInfo">完成</a-button>
		</div>
		<div class="df aic jcsb" v-else>
			<div id="info-box" class="rsc">
				<el-avatar shape="square" :size="70" :src="userInfo.avatar" />
				<div class="cbs" style="height: 45px;margin-left: 34px; font-size: 1.1rem;">
					<span>{{params.username||params.phone}}</span>
					<span style="color: #999; font-size: .8rem;">上次登录时间：{{userInfo.createtime|dateFormat}}</span>
				</div>
			</div>
			<a-button style="margin-right: 20px;" @click="isEdit=true">编辑</a-button>
		</div>
		<el-divider />

		<div id="wallet-box" class="rsc">
			<div class="wallet-cont cas" style="padding-left: 30px;">
				<span>人民币</span>
				<div>{{userInfo.balance}} <span style="color: #DD0000">元</span></div>
			</div>
			<div style="height: 80px; width: 1px; background: #B5B5B5;"></div>
			<div class="wallet-cont cas" style="padding-left: 56px;">
				<span>积分</span>
				<div>{{userInfo.integral}} <span style="color: #DD0000">分</span></div>
			</div>
			<el-input @change="toRecharge" clearable style="width: 250px;" placeholder="请输入充值金额" v-model.number="money">
				<el-button :loading="buttonLoading" @click="toRecharge" style="color: #FFF;" slot="append"
					type="primary">充值
				</el-button>
			</el-input>
		</div>
		<el-dialog @closed="buttonLoading=false" :modal="false" width="20%" center title="打开微信扫码"
			:visible.sync="dialogVisible">
			<div class="df jcc">
				<el-result v-if="showResult" icon="success" title="充值成功" subTitle="点击按钮关闭">
					<template slot="extra">
						<el-button type="primary" @click="dialogVisible=false" size="medium">关闭</el-button>
					</template>
				</el-result>
				<el-image :src="payInfo" v-else></el-image>
			</div>
		</el-dialog>
		<div class="table-wrap">
			<div class="theader rsc">
				<div class="col_1">日期</div>
				<div class="col_2">说明</div>
				<div class="col_3">金额</div>
				<div class="col_4">收入积分</div>
				<div class="col_5" style="padding-right: 30px;">状态</div>
			</div>

			<div class="infinite-list-wrapper" @scroll="load">
				<div class="tr rsc" v-for="item in list">
					<div class="col_1">{{item.createTime}}</div>
					<div class="col_2">{{item.title}}</div>
					<div class="col_3">{{item.number}}</div>
					<div class="col_4">{{item.pm}}</div>
					<div class="col_5" style="color: #3F7CF8">已到账</div>
				</div>
				<div class="rcc">
					<p v-if="loading">加载中...</p>
					<p v-if="nomore">没有更多了</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			nomore: false,
			showResult: false,
			buttonLoading: false,
			dialogVisible: false,
			isEdit: false,
			loading: false,
			userInfo: {},
			token: localStorage.getItem('token'),
			params: {
				username: '',
				phone: '',
				avatar: ''
			},
			money: '',
			payInfo: '',
			list: [],
			query: {
				page: 1,
				limit: 10
			},
			totalPages: 0
		}),
		mounted() {
			this.getUserInfo()
			this.userBill()
		},
		methods: {
			async userBill() {
				let {
					list,
					total
				} = await this.$api.rechargeBill(this.query)
				this.list = [...this.list, ...list]
				this.totalPages = Math.ceil(total / this.query.limit)
			},
			async toRecharge() {
				if (!this.money) return this.$warn('充值金额不能为空')
				if (this.money <= 0) return this.$warn('充值金额不能小于零')
				this.buttonLoading = true
				let {
					payInfo,
					order_id
				} = await this.$api.toRecharge(this.money);
				this.dialogVisible = true
				this.payInfo = payInfo
				let timer = setInterval(async () => {
					let {
						result
					} = await this.$api.queryOrderPay(order_id)
					if (result == 'success') {
						clearInterval(timer)
						this.showResult = true
						this.userBill()
					}
				}, 5000)
			},
			async changeUserInfo() {
				this.isEdit = false
				await this.$api.changeUserInfo(this.params)
				this.$message.success('修改成功')
				this.getUserInfo()
			},
			handleAvatarSuccess(res, file) {
				this.params.avatar = res.data
			},
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) this.$message.error('上传头像图片大小不能超过 2MB!');
				return isLt2M;
			},
			async getUserInfo() {
				let {
					userInfo
				} = await this.$api.userInfo()
				this.userInfo = userInfo
				this.params.username = userInfo.username
				this.params.phone = userInfo.phone
				this.params.avatar = userInfo.avatar
			},
			load(e) {
				if (e.srcElement.scrollTop + e.srcElement.offsetHeight > e.srcElement.scrollHeight - 5) {
					this.query.page++
					if (this.query.page >= this.totalPages) {
						this.nomore = true;
					} else {
						this.loading = true
						this.userBill()
						this.loading = false
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		line-height: 78px;
		text-align: center;
	}

	.avatar {
		width: 78px;
		height: 78px;
		display: block;
	}

	#info-box {
		// padding: 0 9px;
		padding: 10px 9px;
	}

	#wallet-box {
		height: 90px;

		.wallet-cont {
			width: 280px;
			height: 100%;
			line-height: 100%;
			color: #DD0000;
			font-size: 1.7rem;

			span {
				font-size: 1rem;
				color: #000;
			}
		}

		.el-button {
			width: 100px;
			height: 40px;
			background: #FFCC00;
			border: none;
			font-size: 1rem;


		}

		.el-button:hover {
			background: rgba(#FFCC00, .8)
		}
	}

	ul li {
		list-style: none;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	.table-wrap {
		margin-top: 60px;

		.col_1 {
			width: 25%;
		}

		.col_2 {
			width: 23%;
		}

		.col_3 {
			width: 17%;
		}

		.col_4 {
			width: 20%;
		}

		.col_5 {
			width: 15%;
		}

		.theader {
			background: #F9F9F9;
			line-height: 45px;
			font-size: .95rem;
			text-align: center;
		}

		.infinite-list-wrapper {
			height: 250px;
			overflow: auto;

			.tr {
				height: 43px;
				text-align: center;
				font-size: .93rem;
				border-bottom: 1px solid #F1F1F1;
			}
		}
	}
</style>
